import { types, Instance } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

export type ToolInstance = Instance<typeof Tool>


const Tool = types
  .model('Tool', {
    id: types.optional(types.identifier, uuid),
    toolName: types.optional(types.string, ''),
    toolDepth: types.optional(types.number, 0),
  })
  .views((self) => ({
  }))
  .actions((self) => {
    return {
      updateName(name: string) {
        self.toolName = name;
      },
      updateDepth(depth: number) {
        self.toolDepth = depth;
      },
    }
  })

export default Tool
