import 'reflect-metadata'
import PipeTally, { PipeTallyInstance } from 'app/models/PipeTally'
import TripSheet, { TripSheetInstance } from 'app/models/TripSheet'
import KillSheet, { KillSheetInstance } from 'app/models/KillSheet'
import { KickCalculator } from 'app/models/KickCalculator'
import OverpullCalculator, { OverpullCalculatorInstance } from 'app/models/OverpullCalculator'
import TrendSheet, { TrendSheetInstance } from 'app/models/TrendSheet'
import WellM, { WellMInstance } from 'app/models/WellM'
import WellSectionM, { WellSectionMInstance } from 'app/models/WellSectionM'
import Tubulars from 'app/models/Tubulars'
import BHA, { BHAInstance } from 'app/models/BHA'
import { types, Instance, flow, getSnapshot } from 'mobx-state-tree'
import { UndoManager } from 'mst-middlewares'
import { createContext, useContext } from 'react'
import { gql } from '@apollo/client'
import { client } from 'app/withApollo'
import pipes from './Pipes.json'
import BOPExtra, { BOPInstance } from 'app/models/BOP'
import { createFreshPart } from 'app/models/BOP/BOPPart'
import Tubular from 'app/models/Tubulars/Tubular'

const MainStore = types
  .model({
    Tubulars: types.maybeNull(Tubulars),
    PipeTally: types.maybeNull(PipeTally),
    LinerTally: types.maybeNull(PipeTally),
    CasingTally: types.maybeNull(PipeTally),
    TripSheet: types.maybeNull(TripSheet),
    TrendSheet: types.maybeNull(TrendSheet),
    KillSheet: types.maybeNull(KillSheet),
    KickCalculator: types.maybeNull(KickCalculator),
    OverpullCalculator: types.maybeNull(OverpullCalculator),
    BHA: types.maybeNull(BHA),
    BOP: types.maybeNull(BOPExtra),
    WellM: types.maybeNull(WellM),
    WellSectionM: types.maybeNull(WellSectionM),
  })
  .actions((self) => ({
    getTubulars: flow(function* (variables: {
      rigId: string
    }) {
      const { data } = (yield client.query({
        query: gql`
        query getPipes(
          $rigId: String!
        ) {
          getPipes(
            rigId: $rigId
          ) {
            __typename
            id
            type
            name
            connection
            OD
            ID
            weight
            disp
            cap
            range
            mutMin
            mutMax
            tenStrength
            torStrength
            standard
            rigId
          }
        }
        `,
        variables,
        fetchPolicy: 'network-only',
      })) as {
        data: {
          getPipes: {
            Tubular: {
              __typename: string
              id: string
              type: string
              name: string
              connection: string
              OD: number
              ID: number
              weight: number
              disp: number
              cap: number
              range: number
              mutMin: number
              mutMax: number
              tenStrength: number
              torStrength: number
              standard: boolean
              rigId: string
            }
          }
        }
      }
      try {
        undoManager.withoutUndo(() => {
          self.Tubulars = Tubulars.create({
            tubulars: JSON.parse(JSON.stringify(data.getPipes)).map((tubular: typeof Tubular) => {
              return Tubular.create(tubular)
            }),
          })
        })
      } catch (e) {
        console.error(e)
      }
    }),

    updateWellMDate: flow(function* (variables: {
      wellName: string
      companyId: string
      rigId: string
      company: string
      rig: string
      updatedDate: Date 
      }) {
        const { data } = (yield client.query({
          query: gql`
            query wellByName(
              $wellName: String!
              $companyId: String!
              $rigId: String!
            ) {
              wellByName(
                wellName: $wellName
                companyId: $companyId
                rigId: $rigId
              ) {
                  id
                  data
              }
            }
          `,
          variables,
        })) as {
          data: {
            wellByName: {
              id: string;
              data: any;
            }
          }
        }

        const wellData = typeof data.wellByName.data === 'string'
          ? JSON.parse(data.wellByName.data)
          : {};

        wellData.UpdatedAt = variables.updatedDate.getTime();
      
      yield client.mutate({
        mutation: gql`
          mutation saveWell(
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveWell(
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(wellData),
        },
      })
    }),


    getWellM: flow(function* (variables: {
      wellName: string
      companyId: string
      rigId: string
      }) {
        const { data } = (yield client.query({
          query: gql`
            query wellByName(
              $wellName: String!
              $companyId: String!
              $rigId: String!
            ) {
              wellByName(
                wellName: $wellName
                companyId: $companyId
                rigId: $rigId
              ) {
                  id
                  data
                  BopEz {
                    data
                  }
              }
            }
          `,
          variables,
        })) as {
          data: {
            wellByName: {
              id: string;
              data: any;
              BopEz: { data: any };
            }
          }
        }

        try {
        undoManager.withoutUndo(() => {
          self.WellM = WellM.create(
            typeof data.wellByName.data === 'string'
              ? JSON.parse(data.wellByName.data)
              : {}
          )

          if(!self.BOP || self.BOP.wellId !== data.wellByName.id) {
            if(data.wellByName.BopEz && typeof data.wellByName.BopEz.data === 'string') {
              self.BOP = BOPExtra.create({wellId: data.wellByName.id, newPart: createFreshPart(), bop: JSON.parse(data.wellByName.BopEz.data)});  
            } else { 
              const partHead = createFreshPart();
              partHead.setMid(0);
              self.BOP = BOPExtra.create({wellId: data.wellByName.id, newPart: createFreshPart(), bop: { parts: [ partHead ] }}); 
            }  
          }
        })
      } catch (e) {
        console.log('error', e)
      }
    }),

    updateWellM: flow(function* (variables: {
      wellName: string,
      company: string,
      rig: string
      updatedDate: Date
    }) {
      self.WellM?.updateWellData(self.WellM?.id, 'UpdatedAt', 'Date', variables.updatedDate.toString())
      
      const snap = getSnapshot(self.WellM as WellMInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveWell(
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveWell(
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      //.then(() => {})
      // .catch((e) => {
      //   console.log('ERROR:', e)
      // })
      //.catch()
    }),

    updateWellSectionDateM: flow(function* (variables: {
      wellName: string
      wellSectionName: string
      companyId: string
      rigId: string
      company: string
      rig: string
      updatedDate: Date
      }) {
        const { data } = (yield client.query({
          query: gql`
            query wellSectionByName(
              $wellName: String!
              $wellSectionName: String!
              $companyId: String!
              $rigId: String!
            ) {
              wellSectionByName(
                wellName: $wellName
                wellSectionName: $wellSectionName
                companyId: $companyId
                rigId: $rigId
              ) {
                  id
                  data
              }
            }
          `,
          variables,
        })) as {
          data: {
            wellSectionByName: {
               id: string;
               data: any
            }
          }
        }

        const wellSectionData = typeof data.wellSectionByName.data === 'string'
        ? JSON.parse(data.wellSectionByName.data)
        : {};

        wellSectionData.UpdatedAt = variables.updatedDate.getTime();


        yield client.mutate({
          mutation: gql`
            mutation saveWellSection(
              $wellName: String!
              $wellSectionName: String!
              $company: String!
              $rig: String!
              $data: JSON!
            ) {
              saveWellSection(
                wellName: $wellName
                wellSectionName: $wellSectionName
                company: $company
                rig: $rig
                pt: $data
              ) {
                id
                data
              }
            }
          `,
          variables: {
            ...variables,
            data: JSON.stringify(wellSectionData),
          },
        })
    }),

    getWellSectionM: flow(function* (variables: {
      wellName: string
      wellSectionName: string
      companyId: string
      rigId: string
      }) {
        const { data } = (yield client.query({
          query: gql`
            query wellSectionByName(
              $wellName: String!
              $wellSectionName: String!
              $companyId: String!
              $rigId: String!
            ) {
              wellSectionByName(
                wellName: $wellName
                wellSectionName: $wellSectionName
                companyId: $companyId
                rigId: $rigId
              ) {
                  id
                  data
              }
            }
          `,
          variables,
        })) as {
          data: {
            wellSectionByName: {
               id: string;
               data: any
            }
          }
        }

        try {
        undoManager.withoutUndo(() => {
          self.WellSectionM = WellSectionM.create(
            typeof data.wellSectionByName.data === 'string'
              ? JSON.parse(data.wellSectionByName.data)
              : {}
          )
        })
      } catch (e) {
        console.log('error', e)
      }
    }),

    updateWellSectionM: flow(function* (variables: {
      wellName: string
      wellSectionName: string
      company: string
      rig: string
      updatedDate: Date
    }) {
      self.WellSectionM?.updateWellSectionData(self.WellSectionM?.id,'UpdatedAt', 'Date', variables.updatedDate.toString());

      const snap = getSnapshot(self.WellSectionM as WellSectionMInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveWellSection(
            $wellName: String!
            $wellSectionName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveWellSection(
              wellName: $wellName
              wellSectionName: $wellSectionName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      //.then(() => {})
      //.catch()
    }),

    getKillSheet: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      companyId: string
      rigId: string
      }) {
        const { data } = (yield client.query({
          query: gql`
            query wellSectionByName(
              $wellSectionName: String!
              $wellName: String!
              $companyId: String!
              $rigId: String!
            ) {
              wellSectionByName(
                wellName: $wellName
                wellSectionName: $wellSectionName
                companyId: $companyId
                rigId: $rigId
              ) {
                id
                KillSheetEz {
                  id
                  wellSectionId
                  data
                }
              }
            }
          `,
          variables,
        })) as {
          data: {
            wellSectionByName: {
              KillSheetEz: { id: string; wellSectionId: string; data: any }
            }
          }
        }

        try {
        undoManager.withoutUndo(() => {
          self.KillSheet = KillSheet.create(
            typeof data.wellSectionByName.KillSheetEz.data === 'string'
              ? JSON.parse(data.wellSectionByName.KillSheetEz.data)
              : {}
          )
        })
      } catch (e) {}
    }),

    updateKillSheet: flow(function* (variables: {
      wellSectionName: string,
      wellName: string,
      company: string,
      rig: string
      }) {
      const snap = getSnapshot(self.KillSheet as KillSheetInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveKillSheet(
            $wellSectionName: String!
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveKillSheet(
              wellSectionName: $wellSectionName
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              wellSectionId
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      // .then(() => {})
      // .catch()
    }),

    getOverpullCalculator: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      companyId: string
      rigId: string
      }) {
        const { data } = (yield client.query({
          query: gql`
            query wellSectionByName(
              $wellSectionName: String!
              $wellName: String!
              $companyId: String!
              $rigId: String!
            ) {
              wellSectionByName(
                wellName: $wellName
                wellSectionName: $wellSectionName
                companyId: $companyId
                rigId: $rigId
              ) {
                id
                OverpullCalcEz {
                  id
                  wellSectionId
                  data
                }
              }
            }
          `,
          variables,
        })) as {
          data: {
            wellSectionByName: {
              OverpullCalcEz: { id: string; wellSectionId: string; data: any }
            }
          }
        }

        try {
        undoManager.withoutUndo(() => {
          self.OverpullCalculator = OverpullCalculator.create(
            typeof data.wellSectionByName.OverpullCalcEz?.data === 'string'
              ? JSON.parse(data.wellSectionByName.OverpullCalcEz.data)
              : {}
          )
        })
      } catch (e) {console.log(e)}
    }),
    

    updateOverpullCalculator: flow(function* (variables: {
      wellSectionName: string,
      wellName: string,
      company: string,
      rig: string
      }) {
      const snap = getSnapshot(self.OverpullCalculator as OverpullCalculatorInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveOverpullCalc(
            $wellSectionName: String!
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveOverpullCalc(
              wellSectionName: $wellSectionName
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id 
              wellSectionId
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      .then((res) => {console.log(res)})
      .catch ((error) => {console.log(error)})
    }),

    getTrendSheet: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      companyId: string
      rigId: string
      }) {
        const { data } = (yield client.query({
          query: gql`
            query wellSectionByName(
              $wellSectionName: String!
              $wellName: String!
              $companyId: String!
              $rigId: String!
            ) {
              wellSectionByName(
                wellName: $wellName
                wellSectionName: $wellSectionName
                companyId: $companyId
                rigId: $rigId
              ) {
                id
                TrendSheetEz {
                  id
                  wellSectionId
                  data
                }
              }
            }
          `,
          variables,
        })) as {
          data: {
            wellSectionByName: {
              TrendSheetEz: { id: string; wellSectionId: string; data: any }
            }
          }
        }

        try {
        undoManager.withoutUndo(() => {
          self.TrendSheet = TrendSheet.create(
            typeof data.wellSectionByName.TrendSheetEz.data === 'string'
              ? JSON.parse(data.wellSectionByName.TrendSheetEz.data)
              : {}
          )
        })
      } catch (e){}
    }),

    updateTrendSheet: flow(function* (variables: {
      wellSectionName: string,
      wellName: string,
      company: string,
      rig: string
    }) {
    
      const snap = getSnapshot(self.TrendSheet as TrendSheetInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveTrendSheet(
            $wellSectionName: String!
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveTrendSheet(
              wellSectionName: $wellSectionName
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              wellSectionId
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      // .then(() => {})
      // .catch()
    }),

    getTripSheet: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      companyId: string
      rigId: string
    }) {
      const { data } = (yield client.query({
        query: gql`
          query wellSectionByName(
            $wellSectionName: String!
            $wellName: String!
            $companyId: String!
            $rigId: String!
          ) {
            wellSectionByName(
              wellName: $wellName
              wellSectionName: $wellSectionName
              companyId: $companyId
              rigId: $rigId
            ) {
              id
              TripSheetEz {
                id
                wellSectionId
                data
              }
            }
          }
        `,
        variables,
      })) as {
        data: {
          wellSectionByName: {
            TripSheetEz: { id: string; wellSectionId: string; data: any }
          }
        }
      }

      try {
      undoManager.withoutUndo(() => {
        self.TripSheet = TripSheet.create(
          typeof data.wellSectionByName.TripSheetEz.data === 'string'
            ? JSON.parse(data.wellSectionByName.TripSheetEz.data)
            : {}
        )
      })
    } catch (e) {}
  }),

  updateTripSheet: flow(function* (variables: {
    wellSectionName: string,
    wellName: string,
    company: string,
    rig: string
  }) {
    const snap = getSnapshot(self.TripSheet as TripSheetInstance)

    yield client.mutate({
      mutation: gql`
        mutation saveTripSheet(
          $wellSectionName: String!
          $wellName: String!
          $company: String!
          $rig: String!
          $data: JSON!
        ) {
          saveTripSheet(
            wellSectionName: $wellSectionName
            wellName: $wellName
            company: $company
            rig: $rig
            pt: $data
          ) {
            id
            wellSectionId
            data
          }
        }
      `,
      variables: {
        ...variables,
        data: JSON.stringify(snap),
      },
    })
    // .then(() => {})
    // .catch()
  }),

    getTally: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      companyId: string
      rigId: string
    }) {
      const { data } = (yield client.query({
        query: gql`
          query wellSectionByName(
            $wellSectionName: String!
            $wellName: String!
            $companyId: String!
            $rigId: String!
          ) {
            wellSectionByName(
              wellName: $wellName
              wellSectionName: $wellSectionName
              companyId: $companyId
              rigId: $rigId
            ) {
              id
              PipeTallyEz {
                id
                wellSectionId
                data
              }
            }
          }
        `,
        variables,
      })) as {
        data: {
          wellSectionByName: {
            PipeTallyEz: { id: string; wellSectionId: string; data: any }
          }
        }
      }

      try {
      undoManager.withoutUndo(() => {
        self.PipeTally = PipeTally.create(
          typeof data.wellSectionByName.PipeTallyEz.data === 'string'
            ? JSON.parse(data.wellSectionByName.PipeTallyEz.data)
            : {}
        )
      })
      } catch (e) {
        console.log(e)
      }
    }),

    updateTally: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      company: string,
      rig: string
    }) {
      
      const snap = getSnapshot(self.PipeTally as PipeTallyInstance)

      yield client.mutate({
        mutation: gql`
          mutation savePipeTally(
            $wellSectionName: String!
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            savePipeTally(
              wellSectionName: $wellSectionName
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              wellSectionId
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      //.then(() => {})
      //.catch();
    }),

    getBha: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      companyId: string
      rigId: string
    }) {
      const { data } = (yield client.query({
        query: gql`
          query wellSectionByName(
            $wellSectionName: String!
            $wellName: String!
            $companyId: String!
            $rigId: String!
          ) {
            wellSectionByName(
              wellName: $wellName
              wellSectionName: $wellSectionName
              companyId: $companyId
              rigId: $rigId
            ) {
              id
              BhaEz {
                id
                wellSectionId
                data
              }
            }
          }
        `,
        variables,
      })) as {
        data: {
          wellSectionByName: {
            BhaEz: { id: string; wellSectionId: string; data: any }
          }
        }
      }

      try {
      undoManager.withoutUndo(() => {
        self.BHA = BHA.create(
          typeof data.wellSectionByName.BhaEz.data === 'string'
            ? JSON.parse(data.wellSectionByName.BhaEz.data)
            : {}
        )
      })
    } catch (e) {}
  }),

    updateBha: flow(function* (variables: {
      wellSectionName: string,
      wellName: string,
      company: string,
      rig: string
    }) {
      const snap = getSnapshot(self.BHA as BHAInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveBha(
            $wellSectionName: String!
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveBha(
              wellSectionName: $wellSectionName
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              wellSectionId
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      // .then(() => {})
      // .catch();
    }),

    updateBop: flow(function* (variables: {
      wellName: string,
      company: string,
      rig: string
    }) {
      if(!self.BOP) return;

      const snap = getSnapshot(self.BOP as BOPInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveBop(
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveBop(
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap.bop),
        },
      })
      // .then(() => {})
      // .catch();
    }),

    getCasingTally: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      companyId: string
      rigId: string
    }) {
      const { data } = (yield client.query({
        query: gql`
          query wellSectionByName(
            $wellSectionName: String!
            $wellName: String!
            $companyId: String!
            $rigId: String!
          ) {
            wellSectionByName(
              wellName: $wellName
              wellSectionName: $wellSectionName
              companyId: $companyId
              rigId: $rigId
            ) {
              id
              CasingTallyEz {
                id
                wellSectionId
                data
              }
            }
          }
        `,
        variables,
      })) as {
        data: {
          wellSectionByName: {
            CasingTallyEz: { id: string; wellSectionId: string; data: any }
          }
        }
      }

      try {
      undoManager.withoutUndo(() => {
        self.CasingTally = PipeTally.create(
          typeof data.wellSectionByName.CasingTallyEz.data === 'string'
            ? JSON.parse(data.wellSectionByName.CasingTallyEz.data)
            : {}
        )
      })
    } catch (e) {
      window.location.href = '/error?message=CasingTallyStateStoreUndo'
    }
  }),

    updateCasingTally: flow(function* (variables: {
      wellSectionName: string
      wellName: string,
      company: string,
      rig: string
    }) {
      const snap = getSnapshot(self.CasingTally as PipeTallyInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveCasingTally(
            $wellSectionName: String!
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveCasingTally(
              wellSectionName: $wellSectionName
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              wellSectionId
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      // .then(() => {})
      // .catch();
    }),

    getLinerTally: flow(function* (variables: {
      wellSectionName: string
      wellName: string
      companyId: string
      rigId: string
    }) {
      const { data } = (yield client.query({
        query: gql`
          query wellSectionByName(
            $wellSectionName: String!
            $wellName: String!
            $companyId: String!
            $rigId: String!
          ) {
            wellSectionByName(
              wellName: $wellName
              wellSectionName: $wellSectionName
              companyId: $companyId
              rigId: $rigId
            ) {
              id
              LinerTallyEz {
                id
                wellSectionId
                data
              }
            }
          }
        `,
        variables,
      })) as {
        data: {
          wellSectionByName: {
            LinerTallyEz: { id: string; wellSectionId: string; data: any }
          }
        }
      }

      try {
      undoManager.withoutUndo(() => {
        self.LinerTally = PipeTally.create(
          typeof data.wellSectionByName.LinerTallyEz.data === 'string'
            ? JSON.parse(data.wellSectionByName.LinerTallyEz.data)
            : {}
        )
      })
    } catch (e) {
      window.location.href = '/error?message=LinerTallyStateStoreUndo'
    }
  }),

    updateLinerTally: flow(function* (variables: {
      wellSectionName: string,
      wellName: string,
      company: string,
      rig: string
    }) {
      const snap = getSnapshot(self.LinerTally as PipeTallyInstance)

      yield client.mutate({
        mutation: gql`
          mutation saveLinerTally(
            $wellSectionName: String!
            $wellName: String!
            $company: String!
            $rig: String!
            $data: JSON!
          ) {
            saveLinerTally(
              wellSectionName: $wellSectionName
              wellName: $wellName
              company: $company
              rig: $rig
              pt: $data
            ) {
              id
              wellSectionId
              data
            }
          }
        `,
        variables: {
          ...variables,
          data: JSON.stringify(snap),
        },
      })
      // .then(() => {})
      // .catch();
    }),
  }))
  .volatile(() => ({
    pipes,
}))

export const store = MainStore.create(
  { BHA: { parts: [] } },
  { maxHistoryLength: 90 }
)

export const undoManager = UndoManager.create(
  {},
  { targetStore: store, maxHistoryLength: 90 }
)

export type MainStoreInstance = Instance<typeof MainStore>
export type UndoManagerInstance = Instance<typeof UndoManager>
export interface StoreInstance {
  undoManager: UndoManagerInstance
  store: MainStoreInstance
}
const MainStoreContext = createContext<null | StoreInstance>(null)

export const Provider = MainStoreContext.Provider

export function useMst(): StoreInstance {
  const store = useContext(MainStoreContext)

  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  } else return store
}

//this decorator doesn't work on
export function disableUndo<T extends any[]>(
  target: Object,
  propertyKey: string,
  descriptor: PropertyDescriptor
) {
  const method = descriptor.value
  descriptor.value = function (...args: T) {
    let type = Reflect.getMetadata('design:type', target, propertyKey)
    if (!(args instanceof type)) {
      throw new TypeError(`Invalid type, got ${typeof args} not ${type}.`)
    }
    return undoManager.withoutUndo(() => {
      return method.apply(this, args)
    })
  }
}
