import { types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

export type UpdateTrendSheetInput = {
  id?: string
}

const TrendSheetRow = types
  .model('TrendSheetRow', {
    id: types.optional(types.identifier, uuid),
    dateTime: types.optional(types.Date, new Date()),
    user: types.optional(types.string, ''),
    depth: types.optional(types.number, 0),
    depthTvd: types.optional(types.number, 0),
    rop: types.optional(types.number, 0),
    wob: types.optional(types.number, 0),
    rpm: types.optional(types.number, 0),
    torque: types.optional(types.number, 0),
    pressureOn: types.optional(types.number, 0),
    pressureOff: types.optional(types.number, 0),
    boostPressure: types.optional(types.number, 0),
    spm: types.optional(types.number, 0),
    flowIn: types.optional(types.number, 0),
    flowOut: types.optional(types.number, 0),
    active: types.optional(types.number, 0),
    mwIn: types.optional(types.number, 0),
    mwOut: types.optional(types.number, 0),
    ecd: types.optional(types.number, 0),
    gas: types.optional(types.number, 0),
    wiUp: types.optional(types.number, 0),
    wiRot: types.optional(types.number, 0),
    wiDown: types.optional(types.number, 0),
    wiFreeRot: types.optional(types.number, 0),
    azimuth: types.optional(types.number, 0),
    inclination: types.optional(types.number, 0),
    comment: types.optional(types.string, ''),
    deleted: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get totalLength() {
        return 999
      },
    }
  })
  .actions((self) => ({
    // update(data: UpdateTrendSheetInput) {
    //   self = { ...self, ...data }
    // },
  }))

export default TrendSheetRow
