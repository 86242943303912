import { MouseEventHandler, useEffect, useState } from 'react';
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import moment from 'moment-timezone';
import {
  TrashActionButton,
} from '../kit/IconButtons'
import { unitConversion } from '../../../utils/helpers'

type TrendTableRowProps = {
    idx: number,
    active: number
    azimuth: number
    comment: string
    dateTime: Date
    depth: number
    depthTvd: number
    ecd: number
    flowIn: number
    flowOut: number
    gas: number
    id: string
    inclination: number
    mwIn: number
    mwOut: number
    pressureOff: number
    pressureOn: number
    boostPressure: number
    rop: number
    role: string
    rpm: number
    spm: number
    torque: number
    units: string
    user: string
    wiDown: number
    wiFreeRot: number
    wiUp: number
    wob: number
    onClick: MouseEventHandler<HTMLDivElement>
}

const TrendTableRow = observer(({ ...props }: TrendTableRowProps) => {
    const {
        idx,
        active,
        azimuth,
        comment,
        dateTime,
        depth,
        depthTvd,
        ecd,
        flowIn,
        flowOut,
        gas,
        id,
        inclination,
        mwIn,
        mwOut,
        pressureOff,
        pressureOn,
        boostPressure,
        role,
        rop,
        rpm,
        spm,
        torque,
        units,
        user,
        wiDown,
        wiFreeRot,
        wiUp,
        wob
} = props

const {
    store: { TrendSheet, PipeTally },
} = useMst()

const [oldDate, setOldDate] = useState('')

useEffect(() => {
  setOldDate(moment(dateTime).format('YYYY-MM-DDTHH:mm'))
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

async function handleTrash(id: any, idx: number) {
  // only allow row removal if RIH mode - not POOH
  if (PipeTally?.editingMode === 'RIH') {
    // unlog the rotating hours for the row and then delete the row
    TrendSheet?.deleteTrendSheetRow(id.id)

  }
}

function checkNewDateValidity(
  idx: number,
  newDate: Date,
  oldDate: Date,
  priorDate: Date,
  nextDate: Date,
  silent: boolean = false
  ) {
  const forwardLimitation = 900000 // 15 minutes
  if (idx > 0) {
    // check if newDate is before priorDate (row 0 has no priorDate)
    if (idx > 0) {
      if (priorDate && newDate < priorDate) {
        if (!silent) {
          alert('Date cannot be before the previous date')
        }
        return false
      }
    }
  }

  // check if newDate is after the next date
  if ((TrendSheet?.rows.length || 0) >= idx + 2) {
    if (newDate >= nextDate) {
      if (!silent) {
        if (newDate >= nextDate) {
          alert('Date cannot be greater than the following reading')
        }
      }
      return false
    }
  }

  // check if newDate is more than forwardLimitation after current dateTime
  if (newDate >= moment().add(forwardLimitation, 'seconds').toDate()) {
    if (!silent) {
      if (newDate >= moment().add(forwardLimitation, 'seconds').toDate()) {
        alert('Date cannot be in the future')
      }
    }
    return false
  }
  return true
}


return (
    <div
      className="trendSheetRow"
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget as Node)) {
          if (checkNewDateValidity(
            idx,
            moment(TrendSheet?.rows[idx]?.dateTime).toDate(),
            moment(oldDate).toDate(),
            moment(TrendSheet?.rows[idx - 1]?.dateTime).toDate(),
            moment(TrendSheet?.rows[idx + 1]?.dateTime).toDate(),
            true
          )) {
            
          }
        }
      }}
    >
      <div className="trendSheetItem">
        {PipeTally?.editingMode === 'RIH' && (role === 'Complete' || role === 'Driller' || role === 'Support') ? (
        <TrashActionButton
          id={id}
          disabled={TrendSheet?.rows.length === 0}
          onClick={(e) => {
            handleTrash({id}, idx)
          }}
        />
        ) : null}
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-dateTime`}
          name="dateTime"
          title="Time"
          type="datetime-local"
          defaultValue={moment.utc(dateTime).local().format('YYYY-MM-DDTHH:mm')}
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'dateTime', 'date', e.target.value, e.target)
          })}
          onBlur={((e) => {
            if (!checkNewDateValidity(
              idx,
              moment(e.target.value).toDate(),
              moment(oldDate).toDate(),
              moment(TrendSheet?.rows[idx - 1]?.dateTime).toDate(),
              moment(TrendSheet?.rows[idx + 1]?.dateTime).toDate()
            )) {
              e.target.value = moment(oldDate).local().format('YYYY-MM-DDTHH:mm')
              TrendSheet?.updateTrendSheetCell(id, 'dateTime', 'date', e.target.value, e.target)
              // e.target.focus()
            }
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
            key={id}
            id={`${id}-user`}
            name="user"
            title="User"
            value={user}
            onFocus={e => e.target.select()}
            onClick={(e) => {
                e.stopPropagation()
            }}
            className="editableTrendCellInput"
            onChange={((e) => {
              TrendSheet?.updateTrendSheetCell(id, 'user', 'string', e.target.value, e.target)
            })}
          />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-depth`}
          name="depth"
          type="number"
          title="Depth"
          style={{ textAlign: 'right' }}
          value={unitConversion('lengthMedium', units, 'out', depth, 15).toFixed(0)}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'depth', 'number', unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString(), e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
            key={id}
            id={`${id}-depthTvd`}
            name="depthTvd"
            type="number"
            title="Depth TVD"
            style={{ textAlign: 'right' }}
            value={unitConversion('lengthMedium', units, 'out', depthTvd, 15).toFixed(0)}
            onFocus={e => e.target.select()}
            onClick={(e) => {
                e.stopPropagation()
            }}
            className="editableTrendCellInput"
            onChange={((e) => {
              TrendSheet?.updateTrendSheetCell(id, 'depthTvd', 'number', unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString(), e.target)
            })}
          />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-rop`}
          name="rop"
          type="number"
          title="ROP"
          style={{ textAlign: 'right' }}
          value={unitConversion('distanceTime', units, 'out', rop, 15).toFixed(0)}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'rop', 'number', unitConversion('distanceTime', units, 'in', parseFloat(e.target.value), 15).toString(), e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-wob`}
          name="wob"
          type="number"
          title="WOB"
          style={{ textAlign: 'right' }}
          value={wob}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'wob', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-rpm`}
          name="rpm"
          type="number"
          title="RPM"
          style={{ textAlign: 'right' }}
          value={rpm}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'rpm', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-torque`}
          name="torque"
          type="number"
          title="Torque"
          style={{ textAlign: 'right' }}
          value={torque}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'torque', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-pressureOn`}
          name="pressureOn"
          type="number"
          title="Pressure On"
          style={{ textAlign: 'right' }}
          value={pressureOn}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'pressureOn', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-pressureOff`}
          name="pressureOff"
          type="number"
          title="Pressure Off"
          style={{ textAlign: 'right' }}
          value={pressureOff}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'pressureOff', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-boostPressure`}
          name="boostPressure"
          type="number"
          title="Boost pressure"
          style={{ textAlign: 'right' }}
          value={boostPressure}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'boostPressure', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-spm`}
          name="spm"
          type="number"
          title="SPM"
          style={{ textAlign: 'right' }}
          value={spm}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'spm', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-flowIn`}
          name="flowIn"
          type="number"
          title="Flow In"
          style={{ textAlign: 'right' }}
          value={flowIn}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'flowIn', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-flowOut`}
          name="flowOut"
          type="number"
          title="Flow Out"
          style={{ textAlign: 'right' }}
          value={flowOut}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'flowOut', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-active`}
          name="active"
          type="number"
          title="Active"
          style={{ textAlign: 'right' }}
          value={active}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'active', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-mwIn`}
          name="mwIn"
          type="number"
          title="MW In"
          style={{ textAlign: 'right' }}
          value={mwIn}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'mwIn', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-mwOut`}
          name="mwOut"
          type="number"
          title="MW Out"
          style={{ textAlign: 'right' }}
          value={mwOut}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'mwOut', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-ecd`}
          name="ecd"
          type="number"
          title="ECD"
          style={{ textAlign: 'right' }}
          value={ecd}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'ecd', 'number', e.target.value, e.target)
          })}
        />
        </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-gas`}
          name="gas"
          type="number"
          title="Gas"
          style={{ textAlign: 'right' }}
          value={gas}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'gas', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-wiUp`}
          name="wiUp"
          type="number"
          title="Up"
          style={{ textAlign: 'right' }}
          value={wiUp}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'wiUp', 'number', e.target.value, e.target)
          })}
        />
      </div>
      {/* <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-wiRot`}
          name="wiRot"
          type="number"
          title="wiRot"
          style={{ textAlign: 'right' }}
          value={wiRot}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'wiRot', 'number', e.target.value)
          })}
        />
      </div> */}
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-wiFreeRot`}
          name="wiFreeRot"
          type="number"
          title="Free Rotation"
          style={{ textAlign: 'right' }}
          value={wiFreeRot}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'wiFreeRot', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-wiDown`}
          name="wiDown"
          type="number"
          title="Down"
          style={{ textAlign: 'right' }}
          value={wiDown}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'wiDown', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
          key={id}
          id={`${id}-azimuth`}
          name="azimuth"
          type="number"
          title="Azimuth"
          style={{ textAlign: 'right' }}
          value={azimuth}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="editableTrendCellInput"
          onChange={((e) => {
            TrendSheet?.updateTrendSheetCell(id, 'azimuth', 'number', e.target.value, e.target)
          })}
        />
      </div>
      <div className="trendSheetItem">
        <input
            key={id}
            id={`${id}-inclination`}
            name="inclination"
            type="number"
            title="Inclination"
            style={{ textAlign: 'right' }}
            value={inclination}
            onFocus={e => e.target.select()}
            onClick={(e) => {
                e.stopPropagation()
            }}
            className="editableTrendCellInput"
            onChange={((e) => {
              TrendSheet?.updateTrendSheetCell(id, 'inclination', 'number', e.target.value, e.target)
            })}
          />
      </div>
      <div className="trendSheetItem">
      <input
            key={id}
            id={`${id}-comment`}
            name="comment"
            title="Comment"
            value={comment}
            onFocus={e => e.target.select()}
            onClick={(e) => {
                e.stopPropagation()
            }}
            className="editableTrendCellInput"
            onChange={((e) => {
              TrendSheet?.updateTrendSheetCell(id, 'comment', 'string', e.target.value, e.target)
            })}
          />
      </div>
    </div>
  )
})

export default TrendTableRow
