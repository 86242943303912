import { useEffect, useContext, useState } from 'react'
import { UserContext } from 'App'
import { useQuery, gql } from '@apollo/client'
import { UserFields } from 'api/fragments'
import { SectionTabs } from '../WellSection/SectionTabs'
import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import { RouterContext } from '../Router'
import Settings from './Settings'
import TrendTable from './TrendTable'
import warning from '../../../assets/images/icons/icon_warning.svg'
import '../TrendSheet/TrendSheet.css'

export type User = {
  id: string
  email: string
  password: string
  firstName: string | null
  secondName: string | null
  companyId: string | null
  rigId: string | null
  createdAt: Date
  permissions: string[]
  scope: string[]
  company: {
      id: string
      name: string
  }
  rig: {
      id: string
      name: string
      type: string
  }
} | null

const TrendSheetUi = observer(() => {
    const { locationState } = useContext(RouterContext)
    const {
        store: { TrendSheet, BHA, CasingTally, LinerTally },
    } = useMst()

    const { user, setUser } = useContext(UserContext)
    const [role, setRole] = useState('')

    const { loading } = useQuery<{ me: User }>(
        gql`
            ${UserFields}
            query me {
            me {
                ...UserFields
                company {
                    name
                }
            }
        }
        `,
        {
            onCompleted(data) {
                setUser && setUser(data.me)
            },
            onError(err) {
                console.error(err)
            },
        }
    )

    useEffect(() => {
        if (TrendSheet) {
          // if it is the first use configure the first row
          let TrendSheetDefaultRowId = '-'
          if (TrendSheet?.rows.length > 0) {
            // get the id of the first row, is it blank?
            TrendSheetDefaultRowId = TrendSheet.getFirstTrendSheetRow().id
          }
          // if the first id is blank it is a first use case, delete starter row
          if (TrendSheetDefaultRowId === '') {
            TrendSheet?.deleteTrendSheetRow(TrendSheetDefaultRowId)
            // and add an operational blank row
            TrendSheet?.createEmptyRow(`${user?.firstName} ${user?.secondName}`)
          }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TrendSheet])

    useEffect(() => {
        if (user && user?.permissions.length > 0) {
            const viewer: Array<string> = ['read']
            const driller: Array<string> = ['read', 'write']
            const complete: Array<string> = ['read', 'full']
            const supervisor: Array<string> = ['read', 'owner']
            const support: Array<string> = ['read', 'write', 'owner']

            switch (JSON.stringify(user?.permissions)) {
                case JSON.stringify(support):
                    setRole('Support')
                    break
                case JSON.stringify(complete):
                    setRole('Complete')
                    break
                case JSON.stringify(viewer):
                    setRole('Viewer')
                    break
                case JSON.stringify(driller):
                    setRole('Driller')
                    break
                case JSON.stringify(supervisor):
                    setRole('Supervisor')
                    break
                default:
                    setRole('Viewer')
                    break
            }
        }
    }, [user])

    function checkTrendSheetRequirements() {
        if (BHA && BHA.parts && BHA.parts.length > 0) {
            return true;
        }
        if ((CasingTally && CasingTally.strings.length > 0 && CasingTally.strings[0].list.data.length > 0) || (LinerTally && LinerTally.strings.length > 0 && LinerTally.strings[0].list.data.length > 0)) {
            return true;
        }
        return false
    }

    return (!loading ? (
        <div>
            <SectionTabs />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {checkTrendSheetRequirements() ? (
                    <>
                        <Settings company={user?.company.name || null} firstName={user?.firstName || null} lastName={user?.secondName || null} />
                        <TrendTable firstName={user?.firstName || null} lastName={user?.secondName || null} role={role} />
                    </>
                ) : (
                    <div style={{ color: '#fff', padding: '20px', display: 'flex', flexDirection: 'row' }}>
                    <div><img alt="warning" src={warning} className="filter-blue" style={{ marginTop: '10px', width: '25px', height: '25px', marginRight: '20px' }} /></div>
                    <div style={{ marginTop: '11px', color: '#0096FF' }}>You must create a {locationState?.wellSectionType === 'CSG' ? 'Casing/Liner Tally' : 'BHA' } before you can create a Trend Sheet</div>
                  </div>
                )}
            </div>
        </div>
    ) : null)
})

export default TrendSheetUi
