import { useContext, } from 'react'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { RouterContext } from '../Router'
import { TableHeader } from './TableHeader';
import TrendTableRow from './TrendTableRow';
import { CSVLink } from 'react-csv'
import { cleanUpRogueCharacters, unitConversion } from '../../../utils/helpers'
import '../TrendSheet/TrendSheet.css'
import arrow from '../../../assets/images/icons/icon_arrow.svg'

interface TableProps {
  firstName: string | null
  lastName: string | null
  role: string
}

const TrendTable = observer ((props: TableProps) => {
  const {
    store: { TrendSheet },
  } = useMst()
  const { locationState } = useContext(RouterContext)
  const userRole = props.role
  const headers = [
    { label: 'DateTime', key: 'dateTime' },
    { label: 'User', key: 'user' },
    { label: 'Depth', key: 'depth' },
    { label: 'Depth TVD', key: 'depthTvd' },
    { label: 'ROP', key: 'rop' },
    { label: 'WOB', key: 'wob' },
    { label: 'RPM', key: 'rpm' },
    { label: 'Torque', key: 'torque' },
    { label: 'Pressure On', key: 'pressureOn' },
    { label: 'Pressure Off', key: 'pressureOff' },
    { label: 'Boost pressure', key: 'boostPressure' },
    { label: 'SPM', key: 'spm' },
    { label: 'Flow In', key: 'flowIn' },
    { label: 'Flow Out', key: 'flowOut' },
    { label: 'Active', key: 'active' },
    { label: 'Mud Weight In', key: 'mwIn' },
    { label: 'Mud Weight Out', key: 'mwOut' },
    { label: 'ECD', key: 'ecd' },
    { label: 'Gas', key: 'gas' },
    { label: 'Up', key: 'wiUp' },
    { label: 'Down', key: 'wiDown' },
    { label: 'Free Rotation', key: 'wiFreeRot' },
    { label: 'Azimuth', key: 'azimuth' },
    { label: 'Inclination', key: 'inclination' },
    { label: 'Comment', key: 'comment' }
  ]

  function generateDownload() {
    if (!TrendSheet || TrendSheet.rows.length === 0) {
      return [{"error":"data not ready"}]
    }
    if (TrendSheet && TrendSheet.rows.length > 0) {
        let download = JSON.parse(JSON.stringify(TrendSheet.rows))
        download.forEach(function(x:any){
            x.depth = unitConversion('lengthMedium', locationState?.units, 'out', x.depth, 15).toFixed(0)
            x.depthTvd = unitConversion('lengthMedium', locationState?.units, 'out', x.depthTvd, 15).toFixed(0)
            x.rop = unitConversion('distanceTime', locationState?.units, 'out', x.rop, 15).toFixed(0)
            delete x.id
            delete x.deleted
            delete x.wiRot
            x.dateTime = new Date(x.dateTime).toLocaleString().replace(',', '')
        })
        return download
    } else {
      return [{"error":"data not ready"}]
    }
  }

  function scrollGrid(direction: string){
    const grid = document.getElementById('trendSheet')

    var slideVar = setInterval(function(){
      if (direction === 'left'){
        grid!.scrollLeft -= 1000
      } else {
        grid!.scrollLeft += 1000
      }
      window.clearInterval(slideVar)
    }, 50)
  }

  return (
    <div>
      <div id="trendSheetScrollButtonWrapper">
        <button
          id="trendSheetScrollButtonLeft"
          className="trendSheetScrollButton"
          type="button"
          title="Scroll left"
          onClick={() => scrollGrid('left')}
          >
            <img alt="scroll left" src={arrow} style={{ height: '15px', width: '40px', transform: 'scaleX(-1)'}} />
        </button>
        <button
          id="trendSheetScrollButtonRight"
          className="trendSheetScrollButton"
          type="button"
          title="Scroll right"
          onClick={() => scrollGrid('right')}
        >
          <img alt="scroll right" src={arrow} style={{ height: '15px', width: '40px' }} />
        </button>
      </div>
      <div id="trendSheet">
        <TableHeader firstName={props.firstName || null} lastName={props.lastName || null} role={props.role}  />
        {TrendSheet ? (TrendSheet.rows.map(({ id, ...props }, idx) => (
          <TrendTableRow
            {...props}
            key={id}
            id={id}
            idx={idx}
            role={userRole}
            units={locationState?.units || 'imperial'}
            onClick={function (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
              throw new Error('Function not implemented.');
            }}
          />
        ))) : ('')
        }
      </div>
      <div className='downloadButtonWrapper'>
        <CSVLink data={generateDownload()} headers={headers} filename={`${cleanUpRogueCharacters(window.location.href.split('/')[5])}-TrendSheet-${new Date().toISOString()}_UTC.csv`}>CSV</CSVLink>
      </div>
    </div>
  );
})

export default TrendTable
