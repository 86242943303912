import { useMst } from 'app/store'
import { observer } from 'mobx-react-lite'
import { getLabel, unitConversion} from '../../../utils/helpers'
import { RouterContext } from '../Router'
import { useContext } from 'react'
import {
  AddActionButton,
  TrashActionButton,
} from '../kit/IconButtons'


const Tools = observer(() => {
  const {
    store: { WellSectionM, BHA },
  } = useMst()
  const { locationState } = useContext(RouterContext)
  const isOpenHole = locationState?.wellSectionType === 'OH'
  

  function getBhaTotalLength() {
    if (BHA && BHA.parts.length > 0) {
      let bhaTotalLength = 0
      // eslint-disable-next-line array-callback-return
      BHA?.tally(0).map((part: any, i) => {
        bhaTotalLength += part.length
        if (i === BHA.parts.length - 1) {
          return bhaTotalLength
        }
      })
      return bhaTotalLength
    }
    return 0
  }
 

 return (

  <div className='makeStyles-paper-24' style={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', maxWidth: '46.875rem'}}>
      {isOpenHole ? (

    <table style={{ borderRadius: '8px', backgroundColor: 'rgb(58, 60, 86)', padding: '4px'}}>
      <thead>
        <tr>
          <th style={{width: '2rem'}}>
            <AddActionButton
              style={{ margin: '0px 4px 0px 4px' }}
              title='Add Tool'
              onClick={() => {WellSectionM?.createToolRow('','', 0);}}
            />
          </th>
          <th className="label" style={{ width: '20rem', color: '#5c85f5', marginLeft: '8px', fontStyle: 'italic', fontSize: '12px', textAlign: 'left' }}>
            Name:
          </th>
          <th className="label" style={{ width: '40%', color: '#5c85f5', marginLeft: '8px', fontStyle: 'italic', fontSize: '12px', textAlign: 'left' }}>
            Depth:
          </th>
        </tr>
      </thead>
      
      
      <tbody>
          <tr>
            <td style={{width: '2rem'}}></td>
            <td className="tools-table-last-row" style={{  width: '70%', backgroundColor: '#2D2E44 !important', fontWeight: 400, color: '#fff', margin: 0, padding: '4px' }}>
              Bit Depth:
            </td>
            <td className="tools-table-last-row" style={{  width: '18rem', backgroundColor: '#2D2E44 !important', fontWeight: 400, color: '#fff', margin: 0, padding: '4px' }}>
            {unitConversion('lengthMedium', locationState?.units, 'out', getBhaTotalLength(), 10).toFixed(3) || '0'}
            </td>
            <td className="itemUnit" style={{ fontSize: '12pt', marginLeft: '3px' }}>
                {getLabel('lengthMedium', locationState)}
            </td>
          </tr>
        {WellSectionM?.tools.map((elem, idx) => {
          return (
            <tr 
              key={idx.toString()} 
              style={{backgroundColor: 'rgb(58, 60, 86)' }}>
                <td style={{width: '2rem'}}>
                  <TrashActionButton
                    title='Delete Tool'
                    style={{ margin: '0px 4px 0px 4px' }}
                    onClick={() => {WellSectionM?.removeToolRow(idx);}}
                  />
                </td>
                <td className="tools-table-row" style={{  width: '70%'}}>
                   <input
                      id={'tool-name-' + idx.toString()}
                      style={{ backgroundColor: '#2D2E44 !important', fontWeight: 400, color: '#fff', margin: 0, width: '100%', padding: '4px' }}
                      onFocus={e => e.target.select()}
                      type="text"
                      placeholder="Enter tool's name"
                      value={elem.toolName}
                      className="input"
                      onChange={((e) => {
                        WellSectionM?.updateToolRowName(
                        idx,
                        e.target.value)
                      })}
                  />
                </td>
                <td className="tools-table-row" style={{  width: '18rem' }}>
                      <input
                        id={'tool-depth-' + idx.toString()}
                        style={{ backgroundColor: '#2D2E44 !important', fontWeight: 400, color: '#fff', margin: 0,  width: '100%', padding: '4px' }}
                        onFocus={e => e.target.select()}
                        type="number"
                        value={Number(unitConversion('lengthMedium', locationState?.units, 'out', elem.toolDepth, 10).toFixed(3) || '0')}
                        className="input"
                        onChange={((e) => {
                        WellSectionM?.updateToolRowDepth(
                          idx,
                          Number(unitConversion('lengthMedium', locationState?.units, 'in', parseFloat(e.target.value), 10) || '0'))
                        })}
                      />
                </td>
                <td className="itemUnit" style={{ fontSize: '12pt', marginLeft: '3px' }}>
                    {getLabel('lengthMedium', locationState)}
                </td>
              </tr>
          )
        }
        )}
      </tbody>
    </table>
    ) : ''}
  </div>
 )
})

export default Tools
