import { useEffect, useState, useContext } from 'react'
import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { useLocation } from 'react-router-dom'
import icon_plus_circle from '../../../assets/images/icons/icon_plus_circle.svg'
import icon_minus_circle from '../../../assets/images/icons/icon_minus_circle.svg'
import icon_clear_circle from '../../../assets/images/icons/icon_clear_circle.svg'
import { unitConversion } from '../../../utils/helpers'
import { underscoreToSpace } from 'utils/helpers'
import { useLazyQuery, gql } from '@apollo/client'
import { isAlive } from 'mobx-state-tree'

interface Props {
  host?: string;
  wellName?: string
  wellSection?: string
}

const DataTable = observer (({host, wellName, wellSection}: Props) => {
  const {
    store: { WellM, WellSectionM, TrendSheet },
  } = useMst()
  const { locationState } = useContext(RouterContext)
  const [activeLiner, setActiveLiner] = useState(1)
  const [activeCasing, setActiveCasing] = useState(1)
  const [units, setUnits] = useState('imperial')
  const [tblDepth, setTblDepth] = useState(0)
  const [tblDepthTvd, setTblDepthTvd] = useState(0)
  const [isLoaded, setIsLoaded] = useState('')

  const location = useLocation()
  const uri = location.pathname

  useEffect(() => {
    if (isLoaded === '') {
      getUnits()
      prepVars()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wellName, uri, isLoaded, WellM, WellSectionM, wellName, wellSection, TrendSheet])

  useEffect(() => {
    if (isLoaded === '') {
      getUnits()
      prepVars()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoaded) {
      prepVars()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded])

  function prepVars() {
    if (host === "section") {
      if (TrendSheet && isAlive(TrendSheet) && TrendSheet.rows.length > 0) {
        setTblDepth(unitConversion('lengthMedium', units, 'out', getLastTrendSheetRow()?.depth || 0, 15))
        setTblDepthTvd(unitConversion('lengthMedium', units, 'out', getLastTrendSheetRow()?.depthTvd || 0, 15))
      }
    }
  }

  let currentWell = wellName
  let currentWellSection = wellSection

  if (!wellName) {
    currentWell = uri.split('/')[2]
  }

  if (!wellSection) {
    currentWellSection = ''
  }

  const variables = {
    wellName: underscoreToSpace(currentWell || ''),
    wellSectionName: underscoreToSpace(currentWellSection || ''),
    companyId: JSON.parse(localStorage.getItem('context') || '').company.id,
    rigId: JSON.parse(localStorage.getItem('context') || '').rig.id,
  }

  const [getUnits] = useLazyQuery(
    gql`
        query wellUnits(
          $wellName: String!
          $companyId: String!
          $rigId: String!
        ) {
          wellByName(
            wellName: $wellName
            companyId: $companyId
            rigId: $rigId
          ) {
            id
            data
            units
            status
          }
        }
    `,
    {
        variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // fetchPolicy: "no-cache",
        onCompleted(data: any) {
          if (data.wellByName.units) {
            setUnits(data.wellByName.units)
          }
          setIsLoaded('loaded')
        },
        onError(err) {
            console.error(err)
        }
    }
  )

  useEffect(() => {
    if (locationState) {
      setUnits(locationState?.units || '')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState])


  useEffect(() => {
    determineCasingVisibility()
    determineLinerVisibility()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
     determineCasingVisibility()
     determineLinerVisibility()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WellM, WellSectionM])


  function setInputFieldValue(field: string, value: number) {
    const elem = document.getElementById(field) as HTMLInputElement
    if (elem) {
      elem.value = value.toString()
    }
  }

  function determineCasingVisibility() {
    if (WellM) {
      if (WellM?.casingSize5 + WellM?.casingId5 + WellM?.casingShoeMd5 + WellM?.casingShoeTvd5 + WellM?.casingTolMd5 + WellM?.casingTolTvd5 > 0) {
        setActiveCasing(5)
      } else if (WellM?.casingSize4 + WellM?.casingId4 + WellM?.casingShoeMd4 + WellM?.casingShoeTvd4 + WellM?.casingTolMd4 + WellM?.casingTolTvd4 > 0) {
        setActiveCasing(4)
      } else if (WellM?.casingSize3 + WellM?.casingId3 + WellM?.casingShoeMd3 + WellM?.casingShoeTvd3 + WellM?.casingTolMd3 + WellM?.casingTolTvd3 > 0) {
        setActiveCasing(3)
      } else if (WellM?.casingSize2 + WellM?.casingId2 + WellM?.casingShoeMd2 + WellM?.casingShoeTvd2 + WellM?.casingTolMd2 + WellM?.casingTolTvd2 > 0) {
        setActiveCasing(2)
      } else if (WellM?.casingSize + WellM?.casingId + WellM?.casingShoeMd + WellM?.casingShoeTvd > 0) {
        setActiveCasing(1)
      } else {
        setActiveCasing(1)
      }
    }
  }


  function determineLinerVisibility() {
    if (WellM) {
      if (WellM?.LinerSize5 + WellM?.LinerId5 + WellM?.LinerShoeMd5 + WellM?.LinerShoeTvd5 + WellM?.LinerTolMd5 + WellM?.LinerTolTvd5 > 0) {
        setActiveLiner(5)
      } else if (WellM?.LinerSize4 + WellM?.LinerId4 + WellM?.LinerShoeMd4 + WellM?.LinerShoeTvd4 + WellM?.LinerTolMd4 + WellM?.LinerTolTvd4 > 0) {
        setActiveLiner(4)
      } else if (WellM?.LinerSize3 + WellM?.LinerId3 + WellM?.LinerShoeMd3 + WellM?.LinerShoeTvd3 + WellM?.LinerTolMd3 + WellM?.LinerTolTvd3 > 0) {
        setActiveLiner(3)
      } else if (WellM?.LinerSize2 + WellM?.LinerId2 + WellM?.LinerShoeMd2 + WellM?.LinerShoeTvd2 + WellM?.LinerTolMd2 + WellM?.LinerTolTvd2 > 0) {
        setActiveLiner(2)
      } else if (WellM?.LinerSize1 + WellM?.LinerId1 + WellM?.LinerShoeMd1 + WellM?.LinerShoeTvd1 + WellM?.LinerTolMd1 + WellM?.LinerTolTvd1 > 0) {
        setActiveLiner(1)
      } else {
        setActiveLiner(1)
      }
    }
    
  }

  function getLastTrendSheetRow() {
    if (TrendSheet) {
        if (TrendSheet.rows.length > 0) {
            return TrendSheet.rows[TrendSheet.rows.length - 1]
        }
    }
    return null
  } 

  return (
    isLoaded === 'loaded' && WellM ? (
    <div className="wellDatatableWrapper">
      <div className="wellDatatableContainer">
        <div className="wellDatatablePanel">
          <div className="wellDatatable">
            <table className="metricTable">
              <thead>
                <tr>
                  <th style={{ width: '16%', textAlign: 'center' }}></th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Size</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>ID</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Shoe Depth MD</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>Shoe Depth TVD</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>TOL/TOC MD</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>TOL/TOC TVD</th>
                  <th style={{ width: '12%', textAlign: 'center' }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>

                {
                  // Casing loop
                  Array.from({length: activeCasing}, (_, i) => i + 1).map((value) => {return (
                    <tr id={'casingrow'.concat((value).toString())} key={'casingrow'.concat((value).toString())}>
                      <td>Casing {[value]}</td>
                      <td>
                        <input
                          id={value === 1 ? 'casingSize' : 'casingSize'.concat((value).toString())}
                          title="Casing Size"
                          type="number"
                          step="0.1"
                          defaultValue={unitConversion('lengthMedium', 'imperial', 'out', value === 1 ? WellM?.casingSize : WellM['casingSize'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(3)}
                          onFocus={e => e.target.select()}
                          onBlur={(e) => {
                            WellM?.updateWellData(
                              WellM.id,
                              value === 1 ? 'casingSize' : 'casingSize'.concat((value).toString()),
                              'string',
                              unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                            )
                            setInputFieldValue(value === 1 ? 'casingSize' : 'casingSize'.concat((value).toString()), parseFloat(e.target.value))
                          }}
                          className="metricItemValueInputTable"
                        />
                      </td>
                      <td>
                        <input
                          id={value === 1 ? 'casingId' : 'casingId'.concat((value).toString())}
                          title="Casing ID"
                          type="number"
                          step="0.1"
                          defaultValue={unitConversion('lengthMedium', 'imperial', 'out', value === 1 ? WellM?.casingId : WellM['casingId'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(3)}
                          onFocus={e => e.target.select()}
                          onBlur={(e) => {
                            WellM?.updateWellData(
                              WellM.id,
                              value === 1 ? 'casingId' : 'casingId'.concat((value).toString()),
                              'string',
                              unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                            )
                            setInputFieldValue(value === 1 ? 'casingId' : 'casingId'.concat((value).toString()), parseFloat(e.target.value))
                          }}
                          className="metricItemValueInputTable"
                          />
                      </td>
                      <td>
                        <input
                          id={value === 1 ? 'casingShoeMd' : 'casingShoeMd'.concat((value).toString())}
                          title="Casing Shoe MD"
                          type="number"
                          step="0.1"
                          defaultValue={unitConversion('lengthMedium', units, 'out', value === 1 ? WellM?.casingShoeMd : WellM['casingShoeMd'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(1)}
                          onFocus={e => e.target.select()}
                          onBlur={(e) => {
                            WellM?.updateWellData(
                              WellM.id,
                              value === 1 ? 'casingShoeMd' : 'casingShoeMd'.concat((value).toString()),
                              'string',
                              unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                            )
                            setInputFieldValue(value === 1 ? 'casingShoeMd' : 'casingShoeMd'.concat((value).toString()), parseFloat(e.target.value))
                          }}
                          className="metricItemValueInputTable"
                        />
                      </td>
                      <td>
                        <input
                            id={value === 1 ? 'casingShoeTvd' : 'casingShoeTvd'.concat((value).toString())}
                            title="Casing Shoe TVD"
                            type="number"
                            step="0.1"
                            defaultValue={unitConversion('lengthMedium', units, 'out', value === 1 ? WellM?.casingShoeTvd : WellM['casingShoeTvd'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(1)}
                            onFocus={e => e.target.select()}
                            onBlur={(e) => {
                              WellM?.updateWellData(
                                WellM.id,
                                value === 1 ? 'casingShoeTvd' : 'casingShoeTvd'.concat((value).toString()),
                                'string',
                                unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                              )
                              setInputFieldValue(value === 1 ? 'casingShoeTvd' : 'casingShoeTvd'.concat((value).toString()), parseFloat(e.target.value))
                            }}
                            className="metricItemValueInputTable"
                          />
                      </td>
                      <td>
                       {value !== 1 ? ( 
                         <input
                         id={'casingTolMd'.concat((value).toString())}
                         title="Casing TOL MD"
                         type="number"
                         step="0.1"
                         defaultValue={unitConversion('lengthMedium', units, 'out',  WellM['casingTolMd'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(1)}
                         onFocus={e => e.target.select()}
                         onBlur={(e) => {
                           WellM?.updateWellData(
                             WellM.id,
                             'casingTolMd'.concat((value).toString()),
                             'string',
                             unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                           )
                           setInputFieldValue('casingTolMd'.concat((value).toString()), parseFloat(e.target.value))
                         }}
                         className="metricItemValueInputTable"
                       />
                       ) : ''}
 
                      </td>
                      <td>
                        {value !== 1 ? (
                          <input
                          id={'casingTolTvd'.concat((value).toString())}
                          title="Liner TOL TVD"
                          type="number"
                          step="0.1"
                          defaultValue={unitConversion('lengthMedium', units, 'out', WellM['casingTolTvd'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(1)}
                          onFocus={e => e.target.select()}
                          onBlur={(e) => {
                            WellM?.updateWellData(
                              WellM.id,
                              'casingTolTvd'.concat((value).toString()),
                              'string',
                              unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                            )
                            setInputFieldValue('casingTolTvd'.concat((value).toString()), parseFloat(e.target.value))
                          }}
                          className="metricItemValueInputTable"
                        />

                        ) : ''       
                       
                        }
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {value === 1 ? (
                           <img id="casingClear1" alt="" src={icon_clear_circle} style={{ marginRight: '15px' }} />
                         ) : ''}
                        
                        {value !== 5 ? (value === activeCasing ? (
                          <img
                          id={value === 1 ? 'casingAdd1' : 'casingAdd'.concat((value+1).toString())}
                          alt="add casing"
                          src={icon_plus_circle}
                          style={{ cursor: 'pointer', marginRight: value === 1 ? '35px' : '0' }}
                          onClick={() => {
                            setActiveCasing((activeCasing+1))
                            WellM.updateWellData(WellM.id, 'casingSize'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'casingId'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'casingShoeMd'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'casingShoeTvd'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'casingTolMd'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'casingTolTvd'.concat((value+1).toString()), 'string', '0')
                          }}
                        />

                        ) : '') : ''
                        }
                   
                        { value !== 1 ? (value === activeCasing ? (
                          <img
                          id={'casingRemove'.concat((value).toString())}
                          alt="remove casing"
                          src={icon_minus_circle}
                          style={{ cursor: 'pointer', marginRight: '0px', marginLeft: '15px' }}
                          onClick={() => {
                            setActiveCasing((activeCasing-1))
                            WellM.updateWellData(WellM.id, value === 1 ? 'casingSize' : 'casingSize'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, value === 1 ? 'casingId' : 'casingId'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, value === 1 ? 'casingShoeMd' : 'casingShoeMd'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, value === 1 ? 'casingShoeTvd' : 'casingShoeTvd'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, value === 1 ? '' : 'casingTolMd'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, value === 1 ? '' : 'casingTolTvd'.concat((value).toString()), 'string', '0')
                          }}
                        />

                        ) : '') : ''}
                        
                      </td>
                    </tr>
                  )})                
                }

                
                 {/* Liner Loop */}
                { Array.from({length: activeLiner}, (_, i) => i + 1).map((value) => {return (
                  <tr id={'linerrow'.concat((value).toString())} key={'linerrow'.concat((value).toString())}>
                    <td>Liner {value}</td>
                    <td>
                      <input
                        id={'LinerSize'.concat((value).toString())}
                        title="Liner Size"
                        type="number"
                        step="0.1"
                        defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM['LinerSize'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(3)}
                        onFocus={e => e.target.select()}
                        onBlur={(e) => {
                          WellM?.updateWellData(
                            WellM.id,
                            'LinerSize'.concat((value).toString()),
                            'string',
                            unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                          )
                          setInputFieldValue('LinerSize'.concat((value).toString()), parseFloat(e.target.value))
                        }}
                        className="metricItemValueInputTable"
                      />
                  </td>
                  <td>
                    <input
                      id={'LinerId'.concat((value).toString())}
                      title="Liner ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM['LinerId'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerId'.concat((value).toString()),
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerId'.concat((value).toString()), parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id={'LinerShoeMd'.concat((value).toString())}
                      title="Liner Shoe MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM['LinerShoeMd'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeMd'.concat((value).toString()),
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeMd'.concat((value).toString()), parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id={'LinerShoeTvd'.concat((value).toString())}
                      title="Liner Shoe TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM['LinerShoeTvd'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerShoeTvd'.concat((value).toString()),
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerShoeTvd'.concat((value).toString()), parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id={'LinerTolMd'.concat((value).toString())}
                      title="Liner TOL MD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM['LinerTolMd'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolMd'.concat((value).toString()),
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolMd'.concat((value).toString()), parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id={'LinerTolTvd'.concat((value).toString())}
                      title="Liner TOL TVD"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', units, 'out', WellM['LinerTolTvd'.concat((value).toString()) as keyof typeof WellM], 15).toFixed(1)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'LinerTolTvd'.concat((value).toString()),
                          'string',
                          unitConversion('lengthMedium', units, 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('LinerTolTvd'.concat((value).toString()), parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                        {value === 1 ? (
                           <img id="LinerClear1" alt="" src={icon_clear_circle} style={{ marginRight: '15px' }} />
                         ) : ''}
                        
                        {value !== 5 ? (value === activeLiner ? (
                          <img
                          id={'LinerAdd'.concat((value+1).toString())}
                          alt="add liner"
                          src={icon_plus_circle}
                          style={{ cursor: 'pointer', marginRight: value === 1 ? '35px' : '0' }}
                          onClick={() => {
                            setActiveLiner((activeLiner+1))
                            WellM.updateWellData(WellM.id, 'LinerSize'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerId'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerShoeMd'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerShoeTvd'.concat((value+1).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerTolTvd'.concat((value+1).toString()), 'string', '0')
                          }}
                        />
                        ) : '') : ''

                        }
                        { value !== 1 ? (value === activeLiner ? (
                          <img
                          id={'LinerRemove'.concat((value).toString())}
                          alt="remove liner"
                          src={icon_minus_circle}
                          style={{ cursor: 'pointer', marginRight: '0px', marginLeft: '15px' }}
                          onClick={() => {
                            setActiveLiner((activeLiner-1))
                            WellM.updateWellData(WellM.id, 'LinerSize'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerId'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerShoeMd'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerShoeTvd'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerTolMd'.concat((value).toString()), 'string', '0')
                            WellM.updateWellData(WellM.id, 'LinerTolTvd'.concat((value).toString()), 'string', '0')
                          }}
                        />

                        ) : '') : ''}
                        
                      </td>
                  </tr>

                 )})
                }
                
                {/* BHA */}
                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>Open Hole</td>
                  <td>
                    <input
                      id="BhaSize"
                      title="BHA Size"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.BhaSize, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'BhaSize',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('BhaSize', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="BhaId"
                      title="BHA ID"
                      type="number"
                      step="0.1"
                      defaultValue={unitConversion('lengthMedium', 'imperial', 'out', WellM?.BhaId, 15).toFixed(3)}
                      onFocus={e => e.target.select()}
                      onBlur={(e) => {
                        WellM?.updateWellData(
                          WellM.id,
                          'BhaId',
                          'string',
                          unitConversion('lengthMedium', 'imperial', 'in', parseFloat(e.target.value), 15).toString()
                        )
                        setInputFieldValue('BhaId', parseFloat(e.target.value))
                      }}
                      className="metricItemValueInputTable"
                    />
                  </td>
                  <td>
                    <input
                      id="BhaShoeMd"
                      title="BHA Shoe MD"
                      type="number"
                      step="0.1"
                      value={tblDepth.toFixed(1)}
                      className={host === "well" ? 'invisible' : 'metricItemValueInputTableDisabled'}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <input
                      id="BhaShoeTvd"
                      title="BHA Shoe TVD"
                      type="number"
                      step="0.1"
                      value={tblDepthTvd.toFixed(1)}
                      className={host === "well" ? 'invisible' : 'metricItemValueInputTableDisabled'}
                      disabled={true}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    ) : (<>'loading...'</>)
  );
})

export default DataTable
