import { observer } from 'mobx-react'
import { CheckMarkButton } from '../kit/RadioButton'
import { Paper } from '../Layout'
import { KeyboardEvent, useContext } from 'react'
import { useMst } from 'app/store'
import TableToolbar from './TableToolbar'
import { RouterContext } from '../Router'
import { unitConversion } from '../../../utils/helpers'

function onPressEnter(event: KeyboardEvent<HTMLInputElement>) {
  if (event.key === 'Enter') {
    (event.target as HTMLInputElement).blur()
  }
}

const NoDataRow = () => (
  <tr style={{ display: 'table-row', width: '100%' }}>
    <td style={{ textAlign: 'left', paddingLeft: '60px', width: '4%' }} colSpan={1}>
      {'No Data'}
    </td>
    <td style={{ width: '12%' }}>{'-'}</td>
    <td style={{ width: '6%' }}>{'-'}</td>
    <td style={{ width: '9%' }}>{'-'}</td>
    <td style={{ width: '9%' }}>{'-'}</td>
    <td style={{ width: '10%' }}>{'-'}</td>
    <td style={{ width: '10%' }}>{'-'}</td>
    <td style={{ minWidth: '23%' }}>{'-'}</td>
    <td style={{ width: '6%' }}>{'-'}</td>
    <td style={{ width: '11%' }}>{'-'}</td>
  </tr>
)

interface TableHeadProps {
  cStr: string
}

const TableHead = observer(({ cStr }: TableHeadProps) => {
  
  const { locationState } = useContext(RouterContext)
  const isOpenHole = locationState?.wellSectionType === 'OH'

  const {
    store: { PipeTally, WellSectionM},
  } = useMst()

  function selectAll(divId: string, sourceCheckbox: HTMLInputElement) {

    // add all input ids to the selected array
    for (let i = 0; i < (PipeTally?.strings.length || 0); i++) {
      if (PipeTally?.strings[i].id === cStr) {
        PipeTally?.strings[i].list.selectAll();
      }
    }

    // show them as checked
    const divElement = document.getElementById(divId) || document.createElement('div');
    let inputElements = divElement.getElementsByTagName('input');
    for (let i = 0; i < inputElements.length; i++) {
      if (inputElements[i].type !== 'radio' ) {
        continue;
      } else {
        inputElements[i].checked = sourceCheckbox.checked;
      }
    }
  }

  //remember all checked rows
  let allChecked = false;
  for (let i = 0; i < (PipeTally?.strings.length || 0); i++) {
    if (PipeTally?.strings[i].id === cStr) {
      allChecked = PipeTally?.strings[i].list.allSelected;
      break;
    }
  }


  return (
    <thead style={{
      display: 'table-header-group',
      position: 'sticky',
      overflow: 'hidden',
      top: 0,
      zIndex: 1
    }}>
    <tr>
      <th colSpan={100}>
        <TableToolbar />
      </th>
    </tr>
    <tr style={{ display: 'table-row', width: '100%' }}>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', borderRight: 'none', width: '4%' }}>
          <input
            id="inputCheckAll"
            onChange={(e) => selectAll('divTbl', e.target as HTMLInputElement)}
            type="checkbox"
            checked={allChecked}
          />
        </th>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', textAlign: 'left', borderLeft: 'none', width: '7%' }}>
          Serial No.
        </th>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '6%' }}>
          Stand No.
        </th>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '9%' }}>Single</th>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '9%' }}>Stand<br/>Length</th>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '10%' }}>Pipe<br/> total</th>
        
        {isOpenHole === true ? 
          WellSectionM?.tools.toReversed().map((e, i) => {
            if(e.toolName && e.toolDepth) {
              return (
                <th key={i}  style={{ backgroundColor: 'rgb(62, 64, 86)', width: '10%' }}>{ e.toolName }</th>
               )
            } else {
              return ''
            }
          }) 
         : ''}
       
        
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '10%' }}>
          Drill pipe +
          <br />{locationState?.wellSectionType === 'OH' ? 'BHA' : 'Casing/Liner'}
        </th>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', textAlign: 'left', paddingLeft: '20px', minWidth: '23%' }}>Comments</th>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '6%' }}>POI</th>
        <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '11%' }}>
          Stick up mid
          <br />
          joint HOR
        </th>
      </tr>
    </thead>
  )
})

const TallyRow = observer((props: TallyRowProps) => {
  const {
    id,
    disabled,
    checked,
    onClick,
    serialNumber,
    standNumber,
    length,
    standLength,
    totalLength,
    comment,
    bhaLength,
    openHole,
  } = props
  const { locationState } = useContext(RouterContext)

  const standNumberNumeric = !isNaN(Number(standNumber))
  // let currLength = 0

  const {
    store: { PipeTally, LinerTally, CasingTally, WellSectionM },
  } = useMst()

  function saveComment(id: string, comment: string) {
    if (PipeTally && PipeTally.currentString) {
      if (PipeTally?.strings.length > 0) {
        for (let i = 0; i < PipeTally.strings[PipeTally.currentString.number - 1].list.data.length; i++) {
          if (PipeTally.strings[PipeTally.currentString.number - 1].list.data[i].id === id) {
            PipeTally.strings[PipeTally.currentString.number - 1].setComment(i, comment)
          }
        }
      }
    }
  }

  function calcTotal(tl: number) {
    const drillPipe = parseFloat(totalLength)
    let priorLength = 0

    // Get casing total length
    if (CasingTally) {
      for (let i = 0; i < CasingTally.strings.length; i++) {
        priorLength += Number(CasingTally.strings[i].totalLength)
      }
    }
    // Get liner total length
    if (LinerTally) {
      for (let i = 0; i < LinerTally.strings.length; i++) {
        priorLength += Number(LinerTally.strings[i].totalLength)
      }
    }

    // Get total length from all previous strings [if exist]
    if (PipeTally && PipeTally.currentString) {
      if (PipeTally?.strings.length > 1) {
        for (let i = 0; i < PipeTally.currentString.number - 1; i++) {
          priorLength += Number(PipeTally.strings[i].totalLength)
        }
      }
    }

    //console.log("Drill Pipe + BHA: dp:" + drillPipe + " pl:" + priorLength + " td:" + tl)

    return (drillPipe + priorLength + tl)
  }



  return (
    <tr style={{ display: 'table-row', width : '100%' }}>
      <td style={{ width: '4%' }}>
        <div style={{ zIndex: -1 }}>
          {!disabled ? (
            <CheckMarkButton
              id={`${serialNumber}-check-mark-button`}
              checked={checked}
              onChange={() => {}}
              onClick={() => {onClick();}}
            />
          ) : null}
        </div>
      </td>
      <td style={{ textAlign: 'left', borderLeft: 'none', width: '7%' }}>{serialNumber}</td>
      <td style={{ width: '6%' }} className={standNumberNumeric ? 'hl' : ''}>
        {standNumber}
      </td>
      <td style={{ width: '9%' }} className={'hl'}>
        {unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(length), 10).toFixed(3)}
      </td>
      <td style={{ width: '9%' }} className={standLength ? 'hl' : ''}>
        {unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(standLength), 10) > 0 ? unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(standLength), 10).toFixed(3) : '-' || '-'}
      </td>
      <td style={{ width: '10%' }} className={'hl'}>
        {unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(totalLength), 10).toFixed(3)}
      </td>
      {WellSectionM?.tools.toReversed().map((e, i) => {

        if(e.toolName && e.toolDepth && openHole) {
          return (
            <td key={i}  style={{ width: '10%' }} className={'hl'}>
              {unitConversion('lengthMedium', locationState?.units, 'out', calcTotal(e.toolDepth)).toFixed(3) || '0'}
            </td>
          )
        } else {
          return ''
        }
        
        }) 
      }
      <td style={{ width: '10%' }} className={'hl'}>
        {unitConversion('lengthMedium', locationState?.units, 'out', calcTotal(openHole ? (bhaLength || 0) : 0), 10).toFixed(3)}
      </td>
      <td style={{ width: '23%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginTop: '3px', width: '100%' }}>
            <input
              key={id}
              id={`${id}-comments`}
              name={id}
              style={{
                minWidth: '100%',
                width: '100%',
              }}
              title="Comments"
              type="text"
              maxLength={500}
              defaultValue={comment}
              onClick={(e) => {
                e.stopPropagation()
              }}
              className="editableInput"
              onKeyDown={onPressEnter}
              onMouseLeave={(e) => {
                saveComment((e.target as HTMLInputElement).name, (e.target as HTMLInputElement).value)
              }}
              onBlur={(e) => {
                saveComment(e.target.name, e.target.value)
              }}
            />
          </div>
        </div>
      </td>
      <td style={{ width: '6%' }}>{'-'}</td>
      <td style={{ width: '11%' }}>{'-'}</td>
    </tr>
  )
})

type TallyRowProps = {
  id: string
  disabled: boolean
  checked: boolean
  serialNumber: string | number
  standNumber: string
  length: string
  depth?: []
  standLength: string
  totalLength: string
  comment: string
  bhaLength: number
  openHole: boolean
  onClick: () => void
}

const PipeTally = observer(() => {
  const { locationState } = useContext(RouterContext)
  const isOpenHole = locationState?.wellSectionType === 'OH'

  const {
    store: { PipeTally },
  } = useMst()

  const isChecked = (id: string) =>
    PipeTally?.currentString.list.selected.indexOf(id) !== -1

  function flipSelectAll(force?: boolean) {
    const elem = document.getElementById('inputCheckAll') as HTMLInputElement
    if (elem) {
      if (force === true) {
        elem.checked = true
      } else {
        elem.checked = false
      }
    }
  }
  return (
    <Paper>
      <div id="divTbl">
        <table className="table" style={{ display: 'table', width: '99%' }}>
          <TableHead 
          cStr={PipeTally?.currentString?.id || ''} 
          />
          
          <tbody style={{ display: 'table-row-group' }}>
            {PipeTally?.currentString.length !== 0 ? (
              PipeTally?.currentString.tally.map(({ id, ...props }) =>  {

              return (
                <TallyRow
                  id={id}
                  key={props.serialNumber}
                  openHole={isOpenHole}
                  disabled={PipeTally.editingMode === 'disabled'}
                  onClick={() => {
                    PipeTally.currentString.list.select(id)
                    if (PipeTally.currentString.list.selected.length === PipeTally.currentString.list.data.length)  {
                      flipSelectAll(true)
                    } else if (PipeTally.currentString.list.selected.length === 0) {
                      flipSelectAll(false)
                    } else {
                      flipSelectAll(false)
                    }
                  }}
                  checked={isChecked(id)}
                  {...props}
                />
              )})
            ) : (
              <NoDataRow />
            )}
          </tbody>
        </table>
      </div>
    </Paper>
  )
})

export default PipeTally
