import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import AddPipe from './AddPipe'
import { ImportButton } from '../kit/SmallWidthButton'
import {
  AddActionButton,
  StashActionButton,
  TrashActionButton,
} from '../kit/IconButtons'
import { ChangeEventHandler, MouseEventHandler, useState, useContext } from 'react'
import { getTallySheet } from 'utils/getTallySheet'
import { unitConversion, checkIsNumber } from '../../../utils/helpers'
import { useMst } from 'app/store'
import * as xlsx from 'xlsx'
import { RouterContext } from '../Router'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import { cleanUpRogueCharacters } from '../../../utils/helpers'
import excel from '../../../assets/images/icons/icon_excel.svg'
import download from '../../../assets/images/icons/icon_export.svg'
import warning from '../../../assets/images/icons/icon_warning.svg'
import React from 'react'
import { createRoot, Root } from 'react-dom/client'

const TableToolbar = observer(() => {
  const {
    store: { PipeTally, CasingTally, LinerTally, BHA, getTubulars, WellSectionM },
  } = useMst()

  const [open, setOpen] = useState(false)
  const [tubularsRefreshed, setTubularsRefreshed] = useState(false)
  const { locationState } = useContext(RouterContext)
  const location = useLocation()
  const uri = location.pathname
  const isOpenHole = locationState?.wellSectionType === 'OH'
  let msgRoot : Root | null = null

  const handleClose = () => {
    setOpen(false)
  }

  const handleSpreadsheet: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files
    if (!files) return
    if (files[0] instanceof Blob) {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target?.result instanceof ArrayBuffer) {
          const data = new Uint8Array(e.target.result)
          const book = xlsx.read(data, { type: 'buffer' })
          const processError = (err: any) => {
            const stdErr = 'There is a problem with the contents of the file being imported. The first worksheet tab should contain two columns - serial numbers in the first column, and numeric lengths in the second.'

            if (err && err.message && err.message.length > 0) {
              err[0] = stdErr
            }

            const errMsg = React.createElement('div', { id: 'tableToolbarMsg' }, changeDirection.render(err[0], () => {}))
            if(!msgRoot) {
              const elem = document.getElementById('importFileMessage') as HTMLInputElement
              msgRoot = createRoot(elem)
            }
            msgRoot.render(errMsg)
          }
          for (let key in book.Sheets) {
            const rawJson = xlsx.utils.sheet_to_json(book.Sheets[key])
            getTallySheet(rawJson, locationState?.units)
              .then((sheet) => {
                PipeTally?.currentString.list.setData(sheet)
              })
              .catch(processError)
            break
          }
        }
      }
      reader.readAsArrayBuffer(files[0])
    }
    e.target.value = ''
    const elem = document.getElementById('icon-button-file') as HTMLInputElement
    if (elem) { elem.value = '' }
  }

  const changeDirection = {
    closeOnOverlayClick: true,
    render: (message: string, onCancel: any) => {
        return (
            <div id="importFileMessage" className="centeredDiv" style={{ backgroundColor: 'firebrick' }}>
                <div style={{ width: '600px', margin: '20px', padding: '20px', marginBottom: '15px' }}>
                    <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                        <img alt="warning" src={warning} style={{ marginTop: '10px', width: '75px', height: '75px', marginRight: '10px' }} />
                    </div>
                    <div style={{ lineHeight: '30px', color: '#FFFFFF', fontWeight: 500, fontSize: '14pt', marginBottom: '30px' }}>
                        {message}
                    </div>
                    <button onClick={closeModal} className="popupAlertButton"> OK </button>
                </div>
            </div>
        )
    }
  }

  function closeModal() {
    if (msgRoot) {
      msgRoot.unmount()
    }
  }

  const handleTrash: MouseEventHandler<HTMLButtonElement> = () => {
    PipeTally?.storePipe({
      direction: 'trash',
    })
  }

  const handleLayOut: MouseEventHandler<HTMLButtonElement> = () => {
    PipeTally?.storePipe({
      direction: 'stash',
    })
  }

  const handleAdd: MouseEventHandler<HTMLButtonElement> = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (!tubularsRefreshed) {
      refreshPipes()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tubularsRefreshed) {
      refreshPipes()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri])

  async function refreshPipes() {
    await getTubulars({ rigId: JSON.parse(localStorage.getItem('context') || '').rig.id })
    await setTubularsRefreshed(true)
  }

  function getBhaCasingLinerLength() {
    let length = 0

    if (isOpenHole && BHA && BHA.parts.length > 0) {
      BHA?.tally(0).map((part, i) => {
      return (
        length += part.length
      )})
    } else {
      if (!isOpenHole && CasingTally && CasingTally?.strings.length > 0) {
        for (let ct = 0; ct < CasingTally!.strings.length; ct++) {
          for (let ci = 0; ci < CasingTally!.strings[ct].list.data.length; ci++) {
            length += CasingTally!.strings[ct].list.data[ci].length
          }
        }
      }

      if (!isOpenHole && LinerTally && LinerTally?.strings.length > 0) {
        for (let lt = 0; lt < LinerTally!.strings.length; lt++) {
          for (let li = 0; li < LinerTally!.strings[lt].list.data.length; li++) {
            length += LinerTally!.strings[lt].list.data[li].length
          }
        }
      }

    }

    return length
  }

     

  function generateDownload() {
    let download:any = []
    let standAccumulator = 0
    let jointAccumulator = 0
    let stringAccumulator = 0
      
      const bhaLinerCasingLength = getBhaCasingLinerLength()
    if (PipeTally && PipeTally?.strings.length > 0) {

      const headerArray = [
        'Serial Number',
        'Single Length',
        'String',
        'Pipe Name',
        'Stand Number',
        'Stand Length',
        'Pipe Total',
      ];
      if(WellSectionM?.tools) {
        for(let tool of WellSectionM.tools.toReversed()) {
          if(tool.toolName && tool.toolDepth) headerArray.push(tool.toolName);
        }
      }
      headerArray.push(
        
        'Total Length + BHA/Casing/Liner'
      );

      download.push(headerArray)


      for (let s = 0; s < PipeTally!.strings.length; s++) {
        jointAccumulator = 0
        let pipe = PipeTally.strings[PipeTally.strings[s].number-1].getPipe(PipeTally.strings[PipeTally.strings[s].number-1].name || '')
        if (pipe) {
          for (let i = 0; i < PipeTally.strings[s].list.data.length; i++) {
            standAccumulator += unitConversion('lengthMedium', locationState?.units, 'out', PipeTally.strings[s].list.data[i].length, 10)
            jointAccumulator += unitConversion('lengthMedium', locationState?.units, 'out', PipeTally.strings[s].list.data[i].length, 10)
            stringAccumulator += unitConversion('lengthMedium', locationState?.units, 'out', PipeTally.strings[s].list.data[i].length, 10)
            const bodyArray = [
              PipeTally.strings[s].list.data[i].serialNumber,
              unitConversion('lengthMedium', locationState?.units, 'out', PipeTally.strings[s].list.data[i].length, 10).toFixed(3),
              s + 1,
              pipe.name,
              PipeTally.strings[s].getReferenceStandList()[(PipeTally.strings[s].list.data[i].standNumber as number) - 1],
              checkIsNumber(parseFloat(PipeTally.strings[s].getReferenceStandList()[(PipeTally.strings[s].list.data[i].standNumber as number) - 1])) ? standAccumulator.toFixed(3) : '',
              jointAccumulator.toFixed(3),
            ]

            if(WellSectionM?.tools) {
              for(let tool of WellSectionM.tools.toReversed()) {
                if(tool.toolName && tool.toolDepth) 
                  bodyArray.push(
                    (stringAccumulator + unitConversion('lengthMedium', locationState?.units, 'out', tool.toolDepth, 10)).toFixed(3)
                 );
              }
            }

            bodyArray.push([
              (stringAccumulator + unitConversion('lengthMedium', locationState?.units, 'out', bhaLinerCasingLength, 10)).toFixed(3)
            ])

            download.push(bodyArray)

            if (checkIsNumber(parseFloat(PipeTally.strings[s].getReferenceStandList()[(PipeTally.strings[s].list.data[i].standNumber as number) - 1])) > 0) {
              standAccumulator = 0
            }
          }
        }
      }      
      return download
    }
  }

  const csvDownload = generateDownload();
  
  return (
    <div id="tableToolbar" >
      <div className="table-toolbar">
        <AddPipe onClose={handleClose} open={open} />
        <div
          style={{
            marginLeft: '12px',
            display: 'flex',
            alignItems: 'center',
            width: '100px',
          }}
        >
          <Link to="/assets/file/template/tally/TallyImport.xls" target="_blank">
            <img alt="excel" src={excel} style={{ marginTop: '6px', padding: '0px', width: '17px', height: '17px' }} />
          </Link>

          <input
            style={{ display: 'none' }}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="icon-button-file"
            type="file"
            multiple={false}
            onChange={handleSpreadsheet}
            // onClick={(e)=> {
            //   (e.target as HTMLInputElement).value = ''
            //  }}
          />

          <label htmlFor="icon-button-file">
            <ImportButton />
          </label>
          <div className="exportButton" style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', marginTop: '-2px', marginLeft: '10px' }}>
            { csvDownload ?
            <CSVLink
               data={csvDownload}
               filename={`${cleanUpRogueCharacters(window.location.href.split('/')[5])}-PipeTally-${new Date().toISOString()}_UTC.csv`}>
               <span style={{ color: 'dodgerblue', fontSize: '12px' }}>Export</span>
               <span>
                <img
                  alt="download"
                  src={download}
                  style={{ marginTop: '0px', marginLeft: '7px', padding: '0px', width: '17px', height: '17px' }}
                />
              </span>
            </CSVLink>
            : ''}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '180px',
            justifyContent: 'center',
          }}
        >
          <AddActionButton
            disabled={
              PipeTally?.currentString.length !== 0 &&
              (PipeTally?.editingMode === 'disabled' ||
                PipeTally?.currentString.list.selected.length !== 1)
            }
            style={{ margin: '0px 4px 0px 4px' }}
            onClick={handleAdd}
          />
          <StashActionButton
            disabled={
              PipeTally?.editingMode === 'disabled' ||
              PipeTally?.currentString.list.selected.length === 0
            }
            style={{ margin: '0px 4px 0px 4px' }}
            onClick={handleLayOut}
          />
          <TrashActionButton
            disabled={
              PipeTally?.editingMode === 'disabled' ||
              PipeTally?.currentString.list.selected.length === 0
            }
            style={{ margin: '0px 4px 0px 4px' }}
            onClick={handleTrash}
          />
        </div>
      </div>
      <div id="importFileMessage" className="popupAlert" />
    </div>
  )
})

export default TableToolbar
